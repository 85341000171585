import { Paragraph, Spacing } from "@boxt/design-system";

import type { BACKGROUND_COLORS } from "@Collections/themeColors";

import type { Card as CardType } from "../../types";

import { Container, HighlightedText, StyledTag, StyledText } from "./styles";

export type Props = {
  cardData: CardType;
  isFirst: boolean;
};

const Card = ({ cardData, isFirst }: Props) => {
  const {
    text,
    text_color: textColor,
    highlighted_text: highlightedText,
    highlighted_text_color: highlightColor,
    tag_text: tagText,
    bg_color: bgColor,
  } = cardData;

  return (
    <Container
      $bgColor={bgColor?.name as keyof typeof BACKGROUND_COLORS}
      ml={{ lg: isFirst ? 0 : 4, sm: 0 }}
      mt={{ lg: 0, sm: isFirst ? 0 : 2 }}
    >
      <Spacing ma={3}>
        <Spacing mb={{ lg: 8, sm: 6 }}>
          <Paragraph size="large" weight="regular">
            <StyledText $color={textColor?.name}>{text}</StyledText>{" "}
            <HighlightedText $color={highlightColor?.name}>{highlightedText}</HighlightedText>
          </Paragraph>
        </Spacing>
        <StyledTag>
          <Paragraph boxtTheme="slate" weight="regular">
            {tagText}
          </Paragraph>
        </StyledTag>
      </Spacing>
    </Container>
  );
};

export default Card;
