import { Products } from "@Collections/products";

type Props = {
  productType: Products | undefined;
};

type ReturnType = {
  isAirCon: boolean;
  isBatteryOnly: boolean;
  isBoiler: boolean;
  isBoilerPlan: boolean;
  isBoilerRental: boolean;
  isEv: boolean;
  isHeatPump: boolean;
  isHeatlink: boolean;
  isHomeCover: boolean;
  isHomeServe: boolean;
  isHybridHeatPump: boolean;
  isProductRecall: boolean;
  isRepair: boolean;
  isReportIssue: boolean;
  isSolar: boolean;
  isServices: boolean;
  isSmartHome: boolean;
};

const useProductType = ({ productType }: Props): ReturnType => ({
  isAirCon: productType === Products.AC,
  isBatteryOnly: productType === Products.BatteryOnly,
  isBoiler: productType === Products.Boiler,
  isBoilerPlan: productType === Products.BoilerPlan,
  isBoilerRental: productType === Products.BoilerRental,
  isEv: productType === Products.EV,
  isHeatPump: productType === Products.HeatPump,
  isHeatlink: productType === Products.Heatlink,
  isHomeCover: productType === Products.HomeCover,
  isHomeServe: productType === Products.HomeServe,
  isHybridHeatPump: productType === Products.HybridHeatPump,
  isProductRecall: productType === Products.ProductRecall,
  isRepair: productType === Products.Repair,
  isReportIssue: productType === Products.ReportIssue,
  isServices: productType === Products.Services,
  isSmartHome: productType === Products.SmartHome,
  isSolar: productType === Products.Solar,
});

export default useProductType;
