"use client";

import YouTube from "react-youtube";
import styled from "styled-components";
import tinyColor from "tinycolor2";
import { colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  padding-bottom: 56.25%;
  height: 0;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Cover = styled.div<{ $isPlaying: boolean }>`
  z-index: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  background-position: top left;
  background-size: cover;
  animation: ${({ $isPlaying }) => ($isPlaying ? "fade-out 600ms forwards" : "unset")};

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      display: none;
      z-index: -1;
    }
  }
`;

export const Wrapper = styled.div`
  cursor: pointer;
  color: ${colors.white};
  position: relative;
  z-index: 1;
`;

export const PlayButton = styled.button<{ $isPlaying: boolean }>`
  visibility: ${({ $isPlaying }) => ($isPlaying ? "hidden" : "visible")};
  cursor: pointer;
  position: absolute;
  height: ${rem(76)};
  width: ${rem(76)};
  top: calc(50% - 33px);
  left: calc(50% - 33px);
  border-radius: 50%;
  border: none;
  backdrop-filter: blur(4px);
  background: ${tinyColor(colors.coal.normal).setAlpha(0.5).toRgbString()};
  z-index: 1;
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: ${rem(24)};
  width: ${rem(20)};
  left: calc(50% - 20px / 2 + 2px);
  top: calc(50% - 24px / 2);
  z-index: 2;
`;

export const Player = styled(YouTube)<{ $isPlaying: boolean }>`
  visibility: ${({ $isPlaying }) => ($isPlaying ? "visible" : "hidden")};
`;
