import type { ElementType } from "react";
import type { CSSObject } from "styled-components";
import type { ChildNode } from "domhandler/lib/node";
import type { DOMNode, Element, HTMLReactParserOptions } from "html-react-parser";
import { domToReact } from "html-react-parser";
import { camelCase } from "lodash-es";
import type { FontTheme } from "@boxt/design-system";

import { FONT_COLOR } from "@Collections/themeColors";

import type { IHTMLParserComponentMapConfig, IHTMLParserComponentMapConfigItem } from "./config";
import { DEFAULT_COMPONENT_MAP } from "./config";

export type IDOMParserOptions = {
  contentTheme?: keyof typeof FONT_COLOR;
  headingTheme?: keyof typeof FONT_COLOR;
  componentMap?: IHTMLParserComponentMapConfig;
};

const convertCssToObject = (value: string): CSSObject => {
  const regex: RegExp = /([\w-.]+)\s*:([^;]+);?/g;
  const cssObject: CSSObject = {};

  value.replace(regex, (match, key, v) => (cssObject[camelCase(key)] = v.trim()));

  return cssObject;
};

const getAlignment = (style: string): string => {
  if (style === "text-align: center;") {
    return "center";
  } else if (style === "text-align: right;") {
    return "right";
  }

  return "left";
};

const DEFAULT_THEME = "slate";

// Transforms HTML tags from Butter WYSIWYG HTML content to custom components
// NOTE: it is impossible to use TransText as it can not transform attributes,
// also it makes difficult to distinguish between different links behavior/hrefs
const getDOMParserOptions = ({
  contentTheme = DEFAULT_THEME,
  headingTheme = DEFAULT_THEME,
  componentMap = DEFAULT_COMPONENT_MAP,
}: IDOMParserOptions = {}): HTMLReactParserOptions => {
  return {
    replace: (domNode: Element) => {
      const component: IHTMLParserComponentMapConfigItem = componentMap[domNode?.tagName];

      if (domNode?.attribs) {
        if (!(domNode.children || (domNode.children as ChildNode[])?.length)) {
          return null;
        }
        const theme: FontTheme = ["h1", "h2", "h3", "h4", "h5", "h6"].includes(domNode?.tagName)
          ? headingTheme
          : contentTheme;

        if (component) {
          const RenderedAsComponent: ElementType = component.renderedAs as ElementType;
          const StyledAsComponent: ElementType = component.styledAs as ElementType;
          const Component = StyledAsComponent || RenderedAsComponent;

          return (
            <Component
              $styleObject={convertCssToObject(domNode?.attribs?.style || "")}
              align={component?.align ?? getAlignment(domNode?.attribs?.style)}
              boxtTheme={component?.theme ?? (FONT_COLOR[theme] ? theme : DEFAULT_THEME)}
              weight={component?.weight}
              {...(domNode?.attribs?.id ? { id: domNode?.attribs.id } : {})}
              {...(domNode?.attribs?.alt ? { alt: domNode?.attribs.alt } : {})}
              {...(component.styledAs ? { as: RenderedAsComponent } : {})}
              $componentMap={componentMap}
              $contentTheme={contentTheme}
              $domNode={domNode}
              $headingTheme={headingTheme}
            >
              {domToReact(
                domNode.children as DOMNode[],
                getDOMParserOptions({ componentMap, contentTheme, headingTheme }),
              )}
            </Component>
          );
        }
      }
    },
  };
};

export { convertCssToObject, getDOMParserOptions };
