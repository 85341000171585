"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const Container = styled.div`
  position: relative;
  background: ${colors.white};
  display: flex;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  justify-content: center;

  ${breakpoint("md")} {
    border-radius: 0;
  }
`;

export const StyledSpacing = styled.div<{ $averageRatingWidget: boolean }>`
  background: ${colors.white};
  flex: 1;
  max-width: ${rem(1504)};

  ${({ $averageRatingWidget }) => {
    if ($averageRatingWidget) {
      return css`
        max-width: 60rem;
        height: 1.5rem;
        ${includeSpacing({
          mv: { lg: 5, md: 3, sm: 3 },
        })}
      `;
    } else {
      return css`
        height: ${rem(245)};
        ${includeSpacing({
          mb: { md: 2, sm: 4 },
          mh: { lg: 7, md: 5, sm: 2 },
        })}
      `;
    }
  }}
`;

export const TrustpilotFrame = styled.div`
  height: 100%;
`;
