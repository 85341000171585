"use client";

import styled, { css } from "styled-components";
import tinyColor from "tinycolor2";
import { colors, rem } from "@boxt/design-system";

import crossSVG from "@Images/icons/cross.svg";

export const Overlay = styled.div<{ $isOpen: boolean }>`
  display: none;
  z-index: 12;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: ${tinyColor(colors.coal.darker).setAlpha(0.7).toRgbString()};
  transition: all 0.5s ease-in-out;
  justify-content: center;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: flex;
    `}
`;

export const ContentWrapper = styled.div`
  align-self: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
  background: transparent;
  width: ${rem(50)};
  height: ${rem(50)};
  border: 1px solid ${colors.white};
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;

  &:before {
    content: "";
    background-color: ${colors.white};
    mask-image: url(${crossSVG});
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.6);
  }

  &:hover {
    background: ${colors.white};

    &:before {
      background-color: ${colors.slate.normal};
    }
  }
`;
