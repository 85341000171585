"use client";

import styled, { css } from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

import { FONT_COLOR } from "@Collections/themeColors";

export const Container = styled.div`
  height: 100%;
  max-height: ${rem(400)};

  ${breakpoint("sm")} {
    min-height: ${rem(215)};
  }

  ${breakpoint("lg")} {
    min-height: ${rem(200)};
  }
`;

export const Cover = styled.div`
  display: block;
  position: absolute;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  padding: 2rem 1.5rem;
`;

export const SVGWrapper = styled.div<{ $hasDescription?: boolean }>`
  display: flex;
  justify-content: center;
  position: relative;

  ${({ $hasDescription }) =>
    $hasDescription
      ? css`
          padding-bottom: 1.5rem;
        `
      : css`
          top: 50%;
          left: 50%;
          position: relative;
          transform: translate(-50%, -50%);
          padding-bottom: 0;
        `}

  img {
    ${({ $hasDescription }) =>
      $hasDescription
        ? css`
            max-width: 37%;
            min-width: ${rem(135)};
          `
        : css`
            max-width: 45%;
            min-width: ${rem(170)};
          `}
  }
`;

export const StyledDescription = styled.div<{ $textTheme: string; $isContentColorOverriden?: boolean }>`
  flex-grow: 1;

  ${({ $textTheme, $isContentColorOverriden }) =>
    $isContentColorOverriden &&
    FONT_COLOR[$textTheme] &&
    css`
      color: ${FONT_COLOR[$textTheme]};
    `};
`;

export const StyledDiv = styled.div`
  height: 100%;
  overflow: hidden;
`;
