import type { ReactElement } from "react";

import Image from "@Components/Image";
import type { UISlide } from "@Components/pages/common/LandingSliderWithCTA/types";

import { Container, ContentWrapper, Cover, StyledDescription, StyledDiv, SVGWrapper } from "./styles";

export interface Props extends UISlide {
  i18nNamespace: string;
  description: ReactElement;
}

const SliderItem = ({ backgroundImage, logoImage, textTheme, description, isContentColorOverriden }: Props) => {
  const hasDescription = Boolean(description);
  const imageWidth = hasDescription ? 135 : 175;
  const imageHeight = hasDescription ? 29 : 81;

  return (
    <Container>
      <Cover data-testid="slider-item-cover">
        <Image alt="" layout="fill" objectFit="cover" src={backgroundImage} />
      </Cover>
      <ContentWrapper>
        <StyledDiv>
          <SVGWrapper $hasDescription={hasDescription}>
            <Image alt="" height={imageHeight} src={logoImage} width={imageWidth} />
          </SVGWrapper>
          {hasDescription && (
            <StyledDescription $isContentColorOverriden={isContentColorOverriden} $textTheme={textTheme}>
              {description}
            </StyledDescription>
          )}
        </StyledDiv>
      </ContentWrapper>
    </Container>
  );
};

export default SliderItem;
