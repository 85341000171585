import type { HTMLReactParserOptions } from "html-react-parser";
import parse from "html-react-parser";
import type domToReact from "html-react-parser/lib/dom-to-react";
import { sanitize } from "isomorphic-dompurify";

const dangerouslySetInnerHTML = (
  copy: string = "",
  parserOptions: HTMLReactParserOptions = {},
  sanitizeOptions = {},
): ReturnType<typeof domToReact> => parse(sanitize(copy, sanitizeOptions), parserOptions);

export { dangerouslySetInnerHTML };
