import type { ReactNode } from "react";

import { CloseButton, ContentWrapper, Overlay } from "./styles";

export type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const ModalOverlay = ({ children, isOpen, onClose }: Props) => (
  <Overlay $isOpen={isOpen}>
    <CloseButton onClick={onClose} />
    <ContentWrapper>{children}</ContentWrapper>
  </Overlay>
);

export default ModalOverlay;
