"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, Paragraph, rem } from "@boxt/design-system";

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const PricePromise = styled.div`
  display: flex;
  background-color: ${tinyColor(colors.sky.normal).setAlpha(0.25).toRgbString()};
  border: 1px solid ${colors.sky.normal};
  ${includeSpacing({ mb: 4 })}
  border-radius: ${rem(6)};
  padding: ${rem(25)};
`;

export const PricePromiseImage = styled.div`
  display: none;

  ${breakpoint("md")} {
    display: block;
    ${includeSpacing({ mr: 4 })}
  }
`;
export const TickContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`;
export const Tick = styled.div`
  margin-right: ${rem(8)};

  > div {
    font-size: 0;
  }
`;

// As checkbox doesn't have any error messages we just need to style ours to look like carbons
export const StyledFormError = styled(Paragraph)`
  font-size: ${rem(12)};
  color: #da1e28;
`;
