import type { SyntheticEvent } from "react";

export const scrollTop = (event: SyntheticEvent) => {
  event.stopPropagation();

  window.scrollTo({
    behavior: "smooth",
    top: 0,
  });
};
