import type { ReactElement } from "react";
import { Fragment, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { Paragraph } from "@boxt/design-system";

import { ReactComponent as DownArrow } from "@Images/icons/down-arrow.svg";

import { ArrowWrapper, Cell, Container, Header, More, TableGrid, Wrapper } from "./styles";

export type Props = {
  header: { key: string; label: string | ReactElement | ReactElement[] }[];
  rows: { [key: string]: boolean | string | number }[];
  maxRow: number;
  title: string | ReactElement | ReactElement[];
  cellFormatter: { [key: string]: (val: string | number) => string | ReactElement | ReactElement[] };
};

const Table = ({ header, rows, maxRow, title, cellFormatter = {} }: Props) => {
  const { t } = useTranslation("repair/index");
  const [show, setShow] = useState(false);
  const hasMore = rows.length > maxRow;

  const handleExpand = () => {
    setShow(!show);
  };

  const setValue = (row, key) => (cellFormatter[key] ? cellFormatter[key](row[key]) : row[key]);

  const setRow = (neededRows) =>
    neededRows.map((row) => (
      <Fragment key={`row-${row.id}`}>
        {header.map(({ key }) => (
          <Cell key={`row-${key}-${row.id}`}>
            <Paragraph align="left" boxtTheme="slate">
              {setValue(row, key)}
            </Paragraph>
          </Cell>
        ))}
      </Fragment>
    ));

  return (
    <Container>
      <Paragraph align="center" bottom={6} boxtTheme="slate" top={5}>
        {title}
      </Paragraph>
      <Wrapper>
        <TableGrid>
          {header.map(({ key, label }) => (
            <Header key={`header-${key}`}>
              <Paragraph align="left" boxtTheme="slate">
                {label}
              </Paragraph>
            </Header>
          ))}
          {setRow(rows.slice(0, maxRow))}
          {show && setRow(rows.slice(maxRow))}
          {hasMore && !show && (
            <More data-testid="more" onClick={handleExpand}>
              <ArrowWrapper>
                <DownArrow />
              </ArrowWrapper>
              <strong>{t("prices.table.more")}</strong>
            </More>
          )}
        </TableGrid>
      </Wrapper>
    </Container>
  );
};

export default Table;
