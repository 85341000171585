"use client";

import type { CSSObject } from "styled-components";
import styled, { css } from "styled-components";

import type { Alignment } from "@Components/pages/common/HTMLParser/ParsedImage/ParsedImage";

export const Container = styled.span<{ $styleObject?: CSSObject; $alignment?: Alignment }>`
  display: block;
  position: relative;
  width: 100%;
  ${({ $styleObject, $alignment }) => css`
    text-align: ${$alignment};
    img {
      ${$styleObject}
    }
  `}
`;
