import type { CSSObject } from "styled-components";
import type { DOMNode, Element } from "html-react-parser";
import { domToReact } from "html-react-parser";
import type { FontTheme } from "@boxt/design-system";
import { Paragraph } from "@boxt/design-system";

import type { FONT_COLOR } from "@Collections/themeColors";

import type { IHTMLParserComponentMapConfig } from "../config";
import { getDOMParserOptions } from "../helpers";

import { StyledLi } from "./styles";

export type Props = {
  $domNode: Element;
  theme: FontTheme;
  $styleObject: CSSObject;
  $componentMap: IHTMLParserComponentMapConfig;
  $contentTheme: keyof typeof FONT_COLOR;
  $headingTheme: keyof typeof FONT_COLOR;
};

const ParsedLi = ({ $domNode, theme, $styleObject, $componentMap, $contentTheme, $headingTheme }: Props) => (
  <StyledLi $styleObject={$styleObject}>
    <Paragraph as="span" boxtTheme={theme}>
      {domToReact(
        $domNode.children as DOMNode[],
        getDOMParserOptions({ componentMap: $componentMap, contentTheme: $contentTheme, headingTheme: $headingTheme }),
      )}
    </Paragraph>
  </StyledLi>
);

export default ParsedLi;
