import { isEmpty } from "lodash-es";
import { Button, type ButtonTheme, Grid, H3, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";

import BulletSteps from "./components/BulletSteps";
import StepsText from "./components/StepsText";
import type { BulletStep, EasyStep } from "./components/types";
import {
  Container,
  ContentWrapper,
  FlexTemplate,
  MobileCol,
  RowWrapper,
  StepsRow,
  StyledGrid,
  SvgCol,
  TitleWrapper,
} from "./styles";

export type AsEasyAsProps = {
  ctaText?: string | null;
  ctaTheme?: ButtonTheme;
  ctaUrl?: string | null;
  hasBackgroundColor?: boolean;
  id?: string;
  isFullWidth?: boolean;
  showBorderTop?: boolean;
  showBulletSteps?: boolean;
  steps: EasyStep[];
  titleText?: string;
};

const AsEasy = ({
  ctaText,
  ctaTheme = "jade",
  ctaUrl,
  hasBackgroundColor = true,
  id,
  isFullWidth = true,
  showBorderTop = true,
  showBulletSteps,
  steps,
  titleText,
}: AsEasyAsProps) => {
  const bulletSteps: BulletStep[] = steps?.map((step, index) => ({ SVG: step.bulletIcon, key: String(index) }));

  return (
    <StyledGrid>
      <Container
        $hasBackgroundColor={hasBackgroundColor}
        $isFullWidth={isFullWidth}
        $showBorderTop={showBorderTop}
        data-testid="as-easy-as-container"
        id={id}
      >
        <Grid.Row as={RowWrapper}>
          <Grid.Col as={TitleWrapper} lg={{ offset: 1, span: 14 }}>
            <H3 align="center" bottom={{ md: 7, sm: 6 }}>
              {titleText || ""}
            </H3>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row as={RowWrapper}>
          <Grid.Col as={SvgCol} lg={{ offset: 1, span: 14 }} sm={4}>
            <ContentWrapper>
              {steps?.map(({ key, icon }) => (
                <FlexTemplate key={`as-easy-svg-${key}`}>
                  <Image alt={key} height={110} src={icon} width={110} />
                </FlexTemplate>
              ))}
            </ContentWrapper>
          </Grid.Col>
          <Grid.Col as={FlexTemplate} lg={{ offset: 1, span: 14 }} md={8} sm={1}>
            {showBulletSteps && <BulletSteps bulletSteps={bulletSteps} data-testid="as-easy-as-desktop-bullet-steps" />}
          </Grid.Col>
          <Grid.Col as={MobileCol} data-testid="steps-text-mobile-container" sm={4}>
            {!isEmpty(steps) && <StepsText data-testid="steps-text-mobile" showMobileBulletIcons steps={steps} />}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row as={StepsRow}>
          <Grid.Col as={FlexTemplate} lg={{ offset: 1, span: 14 }}>
            {!isEmpty(steps) && (
              <StepsText data-testid="steps-text-desktop" showMobileBulletIcons={false} steps={steps} />
            )}
          </Grid.Col>
        </Grid.Row>
        {ctaText && ctaUrl && (
          <Spacing mb={5}>
            <Button boxtTheme={ctaTheme} data-testid="as-easy-as-cta-button" href={ctaUrl}>
              {ctaText}
            </Button>
          </Spacing>
        )}
      </Container>
    </StyledGrid>
  );
};

export default AsEasy;
