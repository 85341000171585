import styled from "styled-components";
import { breakpoint, colors, rem, Spacing } from "@boxt/design-system";

import { BACKGROUND_COLORS, FONT_COLOR } from "@Collections/themeColors";

export const Container = styled(Spacing)<{ $bgColor?: keyof typeof BACKGROUND_COLORS }>`
  background-color: ${({ $bgColor }) => ($bgColor ? BACKGROUND_COLORS[$bgColor] : colors.white)};
  text-align: start;
  box-shadow: 0 ${rem(15)} ${rem(40)} 0 rgba(0, 0, 0, 0.15);
  border-radius: ${rem(8)};
  overflow: auto;
  flex: 1 1 0;
  position: relative;
`;

export const StyledText = styled.span<{ $color?: string }>`
  color: ${({ $color }) => ($color ? FONT_COLOR[$color] : colors.slate)};
`;

export const HighlightedText = styled.span<{ $color?: string }>`
  color: ${({ $color }) => ($color ? FONT_COLOR[$color] : colors.slate)};
`;

export const StyledTag = styled.div`
  background: ${colors.platinum.lighter};
  border-radius: ${rem(30)};
  padding: ${rem(12)} ${rem(16)};
  width: max-content;
  font-size: ${rem(14)};
  ${breakpoint("lg")} {
    font-size: ${rem(16)};
    padding: ${rem(16)} ${rem(16)};
    position: absolute;
    bottom: ${rem(24)};
  }
`;
