"use client";

import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { rem } from "@boxt/design-system";

export const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountry {
    align-self: flex-start;
    margin-top: ${({ labelText }) => (labelText ? rem(36) : rem(12))};
  }
`;
