import { type HTMLAttributes } from "react";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import type { IStyledComponent } from "styled-components";
import { Button, ChipList, Grid, H4, Spacing, Tabs as TabsComponents } from "@boxt/design-system";

import { BOXT_CUSTOMER_URLS } from "@Collections/routes";
import { actions, category, labels, ReactGAEvent } from "@Lib/googleAnalytics";
import VisibilitySensor from "@Hoc/VisibilitySensor";
import type { Price } from "@Components/pages/common/Prices/types";
import ScrollToButton from "@Components/pages/common/ScrollToButton";
import { ReactComponent as CheckMark } from "@Images/icons/check-mark.svg";
import { ReactComponent as Warranty } from "@Images/icons/warranty.svg";
import { ReactComponent as Workmanship } from "@Images/icons/workmanship.svg";

import { Table } from "./components";
import {
  BackGroundColor,
  ButtonCol,
  Col,
  Container,
  Header,
  linkCol,
  PartsContent,
  PartsWrapper,
  SvgWrapper,
  TableCol,
} from "./styles";

const { TabPanel, TabsContainer, Tabs } = TabsComponents;

const icons = {
  checkMark: <CheckMark />,
  warranty: <Warranty />,
  workmanship: <Workmanship />,
};

export enum Page {
  landing = "landing",
  option = "option",
}

export type Props = {
  wrapper?: IStyledComponent<"web", HTMLAttributes<HTMLDivElement>>;
  prices: Price;
  page?: Page;
};

const Prices = ({ prices, page = Page.landing, wrapper: Wrapper = Container }: Props) => {
  const isLandingPage = page === Page.landing;
  const { t } = useTranslation("repair/index");
  const catagories: Record<string, string>[] = t("prices.catagories", {}, { returnObjects: true });

  const parts: Record<string, string>[] = t("prices.parts.items", {}, { returnObjects: true });

  const getTransComponent = (key: string, values: Record<string, string> = {}) => (
    <Trans components={{ s: <strong /> }} i18nKey={`repair/index:prices.table.${key}`} values={values} />
  );

  const handleAnalyticEvent = (action: string, label: string) => {
    ReactGAEvent({
      action,
      category: isLandingPage ? category.landing : category.options,
      label,
    });
  };

  return (
    <Wrapper id="prices">
      <BackGroundColor>
        <Grid.Row>
          <Grid.Col as={Header}>
            <H4 align="center" top={{ lg: 8, md: 8, sm: 6 }}>
              {t("prices.title")}
            </H4>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col as={Col} lg={{ offset: 1, span: 14 }}>
            <PartsWrapper>
              <ChipList
                chips={parts.map(({ svg, text }) => ({
                  content: <PartsContent boxtTheme="slate">{text}</PartsContent>,
                  icon: <SvgWrapper>{icons[svg]}</SvgWrapper>,
                  key: svg,
                }))}
                title={t("prices.parts.title")}
              />
            </PartsWrapper>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col as={TableCol} lg={{ offset: 2, span: 12 }}>
            <TabsContainer>
              <Tabs align="center" options={catagories.map(({ name }) => name)} />
              {catagories.map(({ key, name }) => (
                <TabPanel key={key}>
                  <VisibilitySensor onVisible={() => handleAnalyticEvent(actions.priceTabs, name)}>
                    <Table
                      cellFormatter={{ price: (price) => t("prices.table.price", { price }) }}
                      header={[
                        {
                          key: "name",
                          label: getTransComponent("name-label", { type: name }),
                        },
                        {
                          key: "price",
                          label: getTransComponent("price-label"),
                        },
                      ]}
                      maxRow={5}
                      rows={prices[key] || []}
                      title={getTransComponent("title")}
                    />
                  </VisibilitySensor>
                </TabPanel>
              ))}
            </TabsContainer>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col as={ButtonCol}>
            <ScrollToButton
              i18nNamespace="repair/index"
              onClick={() => handleAnalyticEvent(actions.readyToGetStarted, labels.priceModule)}
              titleKey={isLandingPage ? "ready-button" : "choose-button"}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col as={linkCol}>
            {isLandingPage ? (
              <Button
                boxtTheme="coral"
                href={BOXT_CUSTOMER_URLS.BOILER}
                onClick={() => handleAnalyticEvent(actions.needNewBoiler, "")}
                rel="noopener noreferrer"
                skin="link"
                target="_blank"
              >
                {t("prices.link")}
              </Button>
            ) : (
              <Spacing />
            )}
          </Grid.Col>
        </Grid.Row>
      </BackGroundColor>
    </Wrapper>
  );
};
export default Prices;
