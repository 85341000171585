"use client";

import styled, { css } from "styled-components";
import { breakpoint, colors, Grid, includeSpacing, rem } from "@boxt/design-system";

export const FlexTemplate = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(FlexTemplate)<{
  $hasBackgroundColor: boolean;
  $isFullWidth: boolean;
  $showBorderTop: boolean;
}>`
  background-color: ${({ $hasBackgroundColor }) => ($hasBackgroundColor ? colors.white : "transparent")};
  ${({ $isFullWidth, $showBorderTop }) => {
    if ($isFullWidth) {
      return css`
        border-top: ${$showBorderTop ? `1px solid ${colors.platinum.light}` : "none"};
        width: 100vw;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
      `;
    } else {
      return css`
        border-radius: 12px;
      `;
    }
  }}
`;

export const RowWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const TitleWrapper = styled(RowWrapper)`
  ${includeSpacing({ mt: { md: 5 } })}
`;

export const MobileCol = styled(FlexTemplate)`
  ${breakpoint("md")} {
    display: none;
  }
`;

export const StepsRow = styled(RowWrapper)`
  display: none;
  ${breakpoint("md")} {
    display: inherit;
  }
`;

export const ContentWrapper = styled(FlexTemplate)`
  flex-direction: row;
  justify-content: space-between;
`;

export const SvgCol = styled.div`
  display: none;
  ${breakpoint("lg")} {
    display: inherit;
  }
`;

export const StyledGrid = styled(Grid)`
  max-width: ${rem(2160)};
`;
