import styled from "styled-components";
import { breakpoint, colors, Spacing } from "@boxt/design-system";

import { BACKGROUND_COLORS } from "@Collections/themeColors";

export const Container = styled(Spacing)<{ $bgColor?: keyof typeof BACKGROUND_COLORS }>`
  background-color: ${({ $bgColor }) => ($bgColor ? BACKGROUND_COLORS[$bgColor] : colors.white)};
  text-align: center;
  overflow: auto;
`;

export const CardWrapper = styled(Spacing)`
  ${breakpoint("lg")} {
    display: flex;
  }
`;
