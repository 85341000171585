import { isEmpty, snakeCase } from "lodash-es";

import type { ValueOf } from "@Collections/types/common";

export const getButterContentMergedWithUi = <T extends object, G extends object>(
  butterContent: T,
  defaultContent: G,
  transformFn: (key: string) => ValueOf<G>,
): G =>
  Object.keys(defaultContent).reduce((acc, curr) => {
    const key = snakeCase(curr);

    if (!isEmpty(butterContent) && butterContent?.[key]?.length) {
      acc[curr] = transformFn(key);
    }

    return acc;
  }, defaultContent);
