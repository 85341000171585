import type { I18n } from "next-translate";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useFsFlag } from "@flagship.io/react-sdk";
import { Field, Form, Formik } from "formik";
import { Button, Checkbox, H4, Paragraph, Spacing } from "@boxt/design-system";

import type { Products } from "@Collections/products";
import FormikPhoneInput from "@Components/Forms/FormikPhoneInput";
import FormikTextInput from "@Components/Forms/FormikTextInput/FormikTextInput";
import { LoadingIcon } from "@Components/pages/report-issue/styles";
import useProductType from "@Hooks/useProductType";
import { ReactComponent as PricePromiseSVG } from "@Images/icons/price-promise.svg";

import { InfoContainer, PricePromise, PricePromiseImage, StyledFormError } from "./styles";
import { CheckboxName, CustomerDetail, validationSchema } from "./validationSchema";

export type Props = {
  i18n: {
    key: string;
    namespace: string;
  };
  onSubmit: (values: any) => void;
  isLoading: boolean;
  productType?: Products;
  hasDiscountHelpText: boolean;
};

const formValues = {
  [CustomerDetail.FirstName]: "",
  [CustomerDetail.Email]: "",
  [CustomerDetail.Phone]: "",
  [CheckboxName.AcceptTerms]: false,
};

const SaveQuote = ({ i18n: { namespace, key }, onSubmit, isLoading, productType, hasDiscountHelpText }: Props) => {
  const { t } = useTranslation(namespace);
  const { t: tCommon }: I18n = useTranslation("common");
  const shouldCollectFirstName: boolean = useFsFlag("quotes-name", false).getValue();
  const { isBoiler } = useProductType({ productType });

  return (
    <InfoContainer>
      <Paragraph bottom={5} boxtTheme="slate">
        {t(`${key}.info`)}
      </Paragraph>
      <Formik
        enableReinitialize
        initialValues={formValues}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        validateOnMount
        validationSchema={validationSchema(shouldCollectFirstName)}
      >
        <Form className="quote-form">
          <>
            {shouldCollectFirstName && (
              <Spacing mb={4}>
                <FormikTextInput
                  hasPlaceholder
                  i18nNamespace={namespace}
                  id={CustomerDetail.FirstName}
                  name={CustomerDetail.FirstName}
                />
              </Spacing>
            )}
            <Spacing mb={4}>
              <FormikTextInput
                hasPlaceholder
                i18nNamespace={namespace}
                id={CustomerDetail.Email}
                name={CustomerDetail.Email}
              />
            </Spacing>
            <Spacing mb={4}>
              <FormikPhoneInput
                hasPlaceholder
                i18nNamespace={namespace}
                id={CustomerDetail.Phone}
                name={CustomerDetail.Phone}
              />
            </Spacing>
            <Spacing mb={4}>
              <Field name={CheckboxName.AcceptTerms}>
                {({ field: { name, value, onChange }, meta }) => (
                  <div data-invalid={meta.touched && meta.error ? meta.error : null}>
                    <Checkbox checked={value} id={name} name={name} onChange={onChange}>
                      <Trans
                        components={{
                          br: <br />,
                          paragraph: <Paragraph as="span" bottom={7} boxtTheme="slate" />,
                        }}
                        i18nKey={`${namespace}:form.accept-terms-quote.label`}
                      />
                    </Checkbox>
                    {meta.touched && meta.error && (
                      <StyledFormError data-testid="terms-error" top={2} weight="regular">
                        {t(`form.errors.${meta.error}`)}
                      </StyledFormError>
                    )}
                  </div>
                )}
              </Field>
            </Spacing>
            <Trans
              components={{
                p: <Paragraph bottom={7} boxtTheme="slate" />,
                privacy: (
                  <Button
                    boxtTheme="coral"
                    href="/privacy-policy"
                    rel="noopener noreferrer"
                    skin="link"
                    target="_blank"
                  />
                ),
              }}
              i18nKey={`${namespace}:${key}.privacy-policy`}
            />
            <Spacing mb={4}>
              <Button
                boxtTheme="jade"
                isFullWidth
                loadingConfig={{
                  copy: tCommon("loading"),
                  icon: {
                    afix: "left",
                    size: "small",
                    svg: <LoadingIcon />,
                  },
                  isLoading,
                }}
                type="submit"
              >
                {t(`${key}.button`)}
              </Button>
            </Spacing>
          </>
        </Form>
      </Formik>
      {isBoiler ? (
        <PricePromise>
          <PricePromiseImage>
            <PricePromiseSVG />
          </PricePromiseImage>
          <div>
            <H4>{t(`${key}.price-promise.title`)}</H4>
            <Paragraph bottom={2} boxtTheme="slate">
              {t(`${key}.price-promise.copy`)}
            </Paragraph>
            <Button
              boxtTheme="coral"
              href="/boilers/the-boxt-price-promise"
              rel="noopener noreferrer"
              skin="link"
              target="_blank"
            >
              {t(`${key}.price-promise.link`)}
            </Button>
          </div>
        </PricePromise>
      ) : null}
      {hasDiscountHelpText ? (
        <>
          <H4 bottom={4} data-testid="discount-help-text">
            {t(`${key}.installation-discount.title`)}
          </H4>
          <Trans
            components={{
              p: <Paragraph />,
              s: <strong />,
            }}
            i18nKey={`${namespace}:${key}.installation-discount.copy`}
          />
        </>
      ) : null}
    </InfoContainer>
  );
};

export default SaveQuote;
