import { type HTMLAttributes } from "react";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import type { IStyledComponent } from "styled-components";
import { Accordion, Button, Grid, H3, Paragraph, Tabs as TabsComponents } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { actions, category, ReactGAEvent } from "@Lib/googleAnalytics";
import VisibilitySensor from "@Hoc/VisibilitySensor";

import { Row } from "./styles";

const { TabPanel, TabsContainer, Tabs } = TabsComponents;

export type Props = {
  wrapper: IStyledComponent<"web", HTMLAttributes<HTMLDivElement>>;
  i18nNamespace: string;
  gaCategory?: string;
  id?: string;
};

const Faq = ({ wrapper: Wrapper, i18nNamespace, gaCategory = category.landing, id }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const options: Array<Record<string, string>> = t("faq.categories", {}, { returnObjects: true });

  const getQuestions = (option: string): Array<Record<string, string>> =>
    t(`faq.question-and-answer.${option}.questions`, {}, { returnObjects: true });

  const handleClick = (event) => {
    event.stopPropagation();
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  const handleAnalyticEvent = (label: string) => {
    ReactGAEvent({
      action: actions.faqTabs,
      category: gaCategory,
      label,
    });
  };

  return (
    <Wrapper id={id}>
      <Grid.Row as={Row}>
        <Grid.Col>
          <H3 align="center" bottom={{ md: 7, sm: 5 }} top={{ md: 9, sm: 7 }}>
            {t("faq.title")}
          </H3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row as={Row}>
        <Grid.Col lg={{ offset: 2, span: 12 }}>
          <TabsContainer panelPadding={{ bottom: 9, top: { md: 6, sm: 4 } }}>
            <Tabs align="center" boxtTheme="slate" options={options.map(({ text }) => text)} top={2} />
            {options.map(({ key: optionKey }) => (
              <TabPanel key={optionKey}>
                <VisibilitySensor onVisible={() => handleAnalyticEvent(optionKey)} />
                {getQuestions(optionKey).map(({ key, text }) => (
                  <Accordion bottom={{ md: 0, sm: 2 }} key={key} title={text} top={{ md: 0, sm: 2 }}>
                    <Trans
                      components={{
                        boilerRepair: (
                          <Button
                            boxtTheme="coral"
                            href="/boilers/boiler-repair"
                            rel="noopener noreferrer"
                            skin="link"
                            target="_blank"
                          />
                        ),
                        contactUs: (
                          <Button
                            boxtTheme="coral"
                            href="/contact-us"
                            rel="noopener noreferrer"
                            skin="link"
                            target="_blank"
                          />
                        ),
                        email: (
                          <Button boxtTheme="coral" href={`mailto://${CONTACT_DETAILS.CUSTOMER_SERVICE}`} skin="link">
                            {CONTACT_DETAILS.CUSTOMER_SERVICE}
                          </Button>
                        ),
                        homeCover: (
                          <Button
                            boxtTheme="coral"
                            href="/home-cover"
                            rel="noopener noreferrer"
                            skin="link"
                            target="_blank"
                          />
                        ),
                        li: <li />,
                        number: (
                          <Button
                            boxtTheme="coral"
                            href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`}
                            skin="link"
                          />
                        ),
                        p: <Paragraph align="left" bottom={5} boxtTheme="slate" />,
                        questionnaire: <Button boxtTheme="coral" onClick={handleClick} skin="link" />,
                        strong: <strong />,
                        ul: <ul />,
                      }}
                      i18nKey={`${i18nNamespace}:faq.question-and-answer.${optionKey}.${key}`}
                    />
                  </Accordion>
                ))}
              </TabPanel>
            ))}
          </TabsContainer>
        </Grid.Col>
      </Grid.Row>
    </Wrapper>
  );
};

export default Faq;
