import { ProgressBar, ProgressContainer } from "./styles";

export type Props = {
  progress: number;
};

const ScreenerProgress = ({ progress }: Props) => {
  return (
    <ProgressContainer>
      <ProgressBar $progress={progress} data-testid="progress-bar" />
    </ProgressContainer>
  );
};

export default ScreenerProgress;
