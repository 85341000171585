import type { ImageProps } from "next/image";

import Image from "@Components/Image";

import { HeroContainer, HeroGradient, SecondaryImage } from "./styles";

export type Props = {
  imgSrc?: string;
  secondaryImgSrc?: string;
  renderGradient?: boolean;
  secondaryImageWidth?: number;
  secondaryImageHeight?: number;
  mainImagePosition?: ImageProps["objectPosition"];
};

const ConfirmationHero = ({
  imgSrc = "https://boxt-bcms.imgix.net/MdVPM0YwQOWnlZ5dzKdI",
  secondaryImgSrc,
  renderGradient = true,
  secondaryImageWidth,
  secondaryImageHeight,
  mainImagePosition = "center",
}: Props) => {
  return (
    <HeroContainer>
      {Boolean(secondaryImgSrc) && (
        <SecondaryImage>
          <Image alt="" height={secondaryImageHeight} src={secondaryImgSrc as string} width={secondaryImageWidth} />
        </SecondaryImage>
      )}
      <Image alt="" layout="fill" objectFit="cover" objectPosition={mainImagePosition} src={imgSrc} />
      {renderGradient && <HeroGradient />}
    </HeroContainer>
  );
};

export default ConfirmationHero;
