"use client";

import type { CSSObject } from "styled-components";
import styled, { css } from "styled-components";

export const StyledLi = styled.li<{ $styleObject: CSSObject }>`
  ${({ $styleObject }) => css`
    ${$styleObject};
  `}

  display: list-item;
  margin-bottom: 1rem;
  padding-left: 1.5rem;

  &::before {
    width: 1.5rem;
    margin-left: -1.5rem;
    display: inline-block;
  }
`;
