import * as Yup from "yup";

import { transformRemoveWhiteSpace } from "@Helpers/validationHelpers/transformRemoveWhiteSpace";
import { EMAIL_REGEX } from "@Collections/regex";
import { validateTelephoneNumber } from "@Components/Forms/PhoneInput/helpers";

export enum CustomerDetail {
  FirstName = "firstName",
  Email = "email",
  Phone = "telephone",
}

export enum CheckboxName {
  AcceptTerms = "acceptTerms-quote",
}

export const validationSchema = (shouldCollectFirstName: boolean) => {
  const schema = Yup.object().shape({
    [CustomerDetail.FirstName]: shouldCollectFirstName
      ? Yup.string().trim().required("first-name-required")
      : Yup.string(),
    [CustomerDetail.Email]: Yup.string().trim().matches(EMAIL_REGEX, "email-invalid").required("email-required"),
    [CustomerDetail.Phone]: transformRemoveWhiteSpace.test(
      CustomerDetail.Phone,
      "phone-invalid",
      validateTelephoneNumber,
    ),
    [CheckboxName.AcceptTerms]: Yup.bool().oneOf([true], "accept-terms-required"),
  });

  return schema;
};
