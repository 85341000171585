"use client";

import styled from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const ProgressContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${rem(6)};
  z-index: 10;
`;

export const ProgressBar = styled.div<{ $progress: number }>`
  background-color: ${colors.coral.lighter};
  border-top-right-radius: ${rem(30)};
  border-bottom-right-radius: ${rem(30)};
  width: ${({ $progress }) => `${$progress}%`};
  height: 100%;
  transition: width 0.3s cubic-bezier(0.4, 0.14, 0.3, 1);
`;
