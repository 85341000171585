import { isEmpty } from "lodash-es";

import type { BulletStep } from "@Components/pages/common/AsEasy/components/types";

import defaultSteps from "../constants";

import { StepsWrapper, StepWrapper } from "./styles";

export type BulletStepProps = {
  bulletSteps?: BulletStep[];
};

const BulletSteps = ({ bulletSteps, ...restProps }: BulletStepProps) => {
  const stepsToRender = isEmpty(bulletSteps) ? defaultSteps : bulletSteps;

  return (
    <StepsWrapper {...restProps}>
      {stepsToRender?.map(({ SVG, key }, index) => (
        <StepWrapper data-testid="bullet-step-icon" key={key}>
          {SVG || defaultSteps[index].SVG}
        </StepWrapper>
      ))}
    </StepsWrapper>
  );
};

export default BulletSteps;
