import type { Translate } from "next-translate";
import Trans from "next-translate/Trans";
import type { KeenSliderOptions } from "keen-slider/react";
import type { FontTheme } from "@boxt/design-system";
import { breakpoints } from "@boxt/design-system";

import { dangerouslySetInnerHTML } from "@Helpers/dangerouslySetInnerHTML";
import { getButterContentMergedWithUi } from "@Helpers/getButterContentMergedWithUI";
import { getDOMParserOptions } from "@Components/pages/common/HTMLParser/helpers";
import { StyledParagraph } from "@Components/pages/common/LandingSliderWithCTA/styles";

import type { ButterSlide, ButterSliderWithCTAFields, LocaleSlide, UIContent, UISlide } from "./types";

const getDefaultUi = (t: Translate, slides: LocaleSlide[], i18nNamespace: string, key: string): UIContent => ({
  ctaText: t("slider-with-cta.cta-text"),
  ctaTheme: "coral",
  header: t("slider-with-cta.header"),
  slides: slides.map(
    (item: LocaleSlide, i) =>
      ({
        backgroundImage: item["background-image"],
        description: item.description ? (
          <Trans
            components={{ p: <StyledParagraph />, s: <strong /> }}
            i18nKey={`${i18nNamespace}:slider-with-cta.slides.${i}.description`}
          />
        ) : null,
        id: `${key}-slide-${i}`,
        isContentColorOverriden: false,
        logoImage: item["logo-image"],
        textTheme: t(`slider-with-cta.slides.${i}.text-theme`),
      }) as UISlide,
  ),
});

const transformFn = (
  key: string,
  content: Omit<ButterSliderWithCTAFields, "cta_theme"> & { cta_theme: string },
  keyName: string,
) =>
  key === "slides"
    ? content[key].map(
        (slide: ButterSlide, i) =>
          ({
            backgroundImage: slide.background_image,
            description: slide.description
              ? dangerouslySetInnerHTML(
                  slide.description,
                  getDOMParserOptions({
                    contentTheme: slide.text_theme?.name as FontTheme,
                    headingTheme: slide.text_theme?.name as FontTheme,
                  }),
                )
              : null,
            id: `${keyName}-slide-${i}`,
            isContentColorOverriden: true,
            logoImage: slide.logo_image,
            textTheme: slide.text_theme?.name || "slate",
          }) as UISlide,
      )
    : content[key];

export const getMergedUi = (
  t: Translate,
  content: ButterSliderWithCTAFields,
  i18nNamespace: string,
  keyName: string,
): UIContent => {
  const slides: LocaleSlide[] = t("slider-with-cta.slides", {}, { returnObjects: true });
  const defaultContent: UIContent = getDefaultUi(t, slides, i18nNamespace, keyName);
  const innerContent: Omit<ButterSliderWithCTAFields, "cta_theme"> & { cta_theme: string } = {
    ...(content || ({} as ButterSliderWithCTAFields)),
    cta_theme: content?.cta_theme?.theme,
  };

  return getButterContentMergedWithUi<Omit<ButterSliderWithCTAFields, "cta_theme"> & { cta_theme: string }, UIContent>(
    innerContent,
    defaultContent,
    (contentKey: string) => transformFn(contentKey, innerContent, keyName),
  );
};

export const getColValues = (slidesLength = 0): { colSpan: number; colOffset: number } => {
  const ratio = slidesLength === 1 ? 6 : 4;
  const colSpan = Math.min(slidesLength * ratio, 16);
  const colOffset = Math.max((16 - colSpan) / 2, 0);

  return {
    colOffset,
    colSpan,
  };
};

export const getKeenSliderOptions = (slides: UISlide[]): KeenSliderOptions => ({
  breakpoints: {
    [`(min-width: ${breakpoints.sm.width})`]: {
      initial: 0,
      loop: true,
      slides: {
        perView: 1,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
    },
    [`(min-width: ${breakpoints.md.width})`]: {
      initial: 1,
      loop: false,
      slides: {
        origin: "center",
        perView: 2,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(1, true, { duration: 0 }),
    },
    [`(min-width: ${breakpoints.lg.width})`]: {
      initial: 0,
      loop: slides?.length > 3,
      slides: {
        perView: 3,
        spacing: 30,
      },
      updated: (slider) => slider.moveToIdx(0, true, { duration: 0 }),
    },
  },
});
