import { ReactComponent as A } from "@Images/a-letter.svg";
import { ReactComponent as B } from "@Images/b-letter.svg";
import { ReactComponent as C } from "@Images/c-letter.svg";

const defaultSteps = [
  { SVG: <A />, key: "a" },
  { SVG: <B />, key: "b" },
  { SVG: <C />, key: "c" },
];

export default defaultSteps;
