"use client";

import styled, { css } from "styled-components";
import { colors, rem } from "@boxt/design-system";

export const ModuleContainer = styled.div<{ $isFlex?: boolean }>`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  ${({ $isFlex }) =>
    $isFlex &&
    css`
      display: flex;
      justify-content: center;
    `};
`;

export const WidthContainer = styled.div<{ $width?: number }>`
  width: ${({ $width }) => ($width ? rem($width) : `100%`)};
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
