import dynamic from "next/dynamic";
import { isEmpty } from "lodash-es";
import { Grid, H3 } from "@boxt/design-system";

import { Title, TrustPilotReviewsContainer } from "@Components/pages/common/Trustpilot/TrustpilotReviewsWidget/styles";

import { TrustpilotWidgetTemplateIds } from "../constants";

import type { ButterTrustpilotWidgetFields, WidgetTheme } from "./../types";

const Trustpilot = dynamic(() => import("@Components/pages/common/Trustpilot"), {
  ssr: false,
});

const defaultValues = {
  stars: "4,5",
  tags: "Repair",
  templateId: TrustpilotWidgetTemplateIds.reviews,
  theme: "light",
};

export type Props = {
  fields: ButterTrustpilotWidgetFields;
};

const TrustpilotReviewsWidget = ({ fields }: Props) => {
  const templateId = isEmpty(fields.template?.name)
    ? defaultValues.templateId
    : TrustpilotWidgetTemplateIds[fields.template?.name as string];

  return (
    <TrustPilotReviewsContainer id={fields.id}>
      <Grid.Row>
        <Grid.Col as={Title}>
          <H3>{fields.title}</H3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Trustpilot
            stars={isEmpty(fields.stars) ? defaultValues.stars : fields.stars}
            tags={fields.tags}
            templateId={templateId}
            theme={(isEmpty(fields.theme?.name) ? defaultValues.theme : fields.theme?.name) as WidgetTheme}
          />
        </Grid.Col>
      </Grid.Row>
    </TrustPilotReviewsContainer>
  );
};

export default TrustpilotReviewsWidget;
