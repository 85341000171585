"use client";

import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

const svgWidth = rem(25);

export const StepsWrapper = styled.div`
  display: none;

  ${breakpoint("md")} {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  padding-top: ${rem(8)};

  svg {
    max-width: ${svgWidth};
    max-height: ${svgWidth};
  }

  &:last-child {
    &:after {
    }
  }

  ${breakpoint("md")} {
    padding-top: ${rem(0)};
    width: 100%;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 0.1;
      background-color: ${colors.slate.lighter};
      position: absolute;
      z-index: -1;
    }

    &:first-child {
      &:after {
        height: 100%;
        right: 0;
        width: 50%;
        bottom: 0;
      }
    }

    &:last-child {
      &:after {
        height: 100%;
        left: 0;
        width: 50%;
        top: 0;
      }
    }
  }
`;
