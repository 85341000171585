import type { InputHTMLAttributes, ReactNode } from "react";
import type { Country, FeatureProps, Value } from "react-phone-number-input";
import { TextInput } from "@boxt/design-system";

import { StyledPhoneInput } from "./styles";

export type Props = {
  testId?: string;
  light?: boolean;
  defaultCountry?: Country;
  value: Value | "";
  onChange: (value: Value) => void;
  invalidText?: string;
  helperText?: string;
  invalid?: boolean;
  labelText?: NonNullable<ReactNode>;
} & FeatureProps<InputHTMLAttributes<HTMLInputElement>>;

const PhoneInput = ({
  value,
  onChange,
  defaultCountry = "GB",
  invalidText,
  testId,
  light,
  helperText,
  invalid,
  labelText,
  ...rest
}: Props) => {
  return (
    <StyledPhoneInput
      {...rest}
      defaultCountry={defaultCountry}
      helperText={helperText}
      inputComponent={TextInput}
      invalid={invalid}
      invalidText={invalidText}
      labelText={labelText}
      light={light}
      onChange={onChange}
      testId={testId}
      value={value}
    />
  );
};

export default PhoneInput;
