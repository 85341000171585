import type { ElementType, ReactElement } from "react";
import { useState } from "react";
import type { AnimatedComponent } from "react-spring";
import { animated, useSpring } from "react-spring";

import VisibilitySensor from "@Hoc/VisibilitySensor";

import { ModuleContainer, WidthContainer } from "../../boilers/boiler-repair/home/styles";

export type Props = {
  children: ReactElement;
  id?: string;
  onVisible?: () => void;
  width?: number;
};

const FadeInModule = ({ id, children, onVisible, width }: Props) => {
  const [isAnimating, setIsAnimating] = useState(false);

  /** @see https://github.com/pmndrs/react-spring/issues/1515 */
  const AnimatedDiv: AnimatedComponent<ElementType> = animated.div;
  const fade = useSpring({
    config: { duration: 800 },
    opacity: isAnimating ? 1 : 0,
  });

  return (
    <VisibilitySensor
      id={id}
      onEnter={() => setIsAnimating(true)}
      onLeave={() => setIsAnimating(false)}
      onVisible={onVisible}
    >
      <ModuleContainer $isFlex={!!width}>
        <WidthContainer $width={width}>
          <AnimatedDiv style={fade}>{children}</AnimatedDiv>
        </WidthContainer>
      </ModuleContainer>
    </VisibilitySensor>
  );
};
export default FadeInModule;
